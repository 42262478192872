<template>
  <form class="contact-form" v-on:submit.prevent="sendEmail()">
      <label id="formName" for="name">{{ $t('view.contact.form.name') }}</label>
      <input
        type="text"
        id="name"
        name="name"
        v-model="contactForm.sender.name"
        required
        class="contact-form__input"
      />

      <label id="formEmail" for="email">{{ $t('view.contact.form.email') }}</label>
      <input
        type="email"
        id="email"
        name="email"
        v-model="contactForm.sender.emailAddress"
        required
        class="contact-form__input"
      />

      <label id="formSubject" for="subject">{{ $t('view.contact.form.subject') }}</label>
      <input
        type="text"
        id="subject"
        name="subject"
        v-model="contactForm.subject"
        required
        class="contact-form__input"
      />

      <label id="formMessage" for="message">{{ $t('view.contact.form.message') }}</label>
      <textarea
        id="message"
        name="message"
        rows="3"
        v-model="contactForm.message"
        required
        class="contact-form__input"
      ></textarea>

      <button
        id="formSubmit"
        type="submit"
        name="submit"
        class="contact-form__button"
        title="Send message"
        :disabled="isSendingMessage"
      >
        {{ $t('view.contact.form.send') }}
      </button>
    </form>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      contactForm: {
        sender: {
          name: '',
          emailAddress: '',
        },
        recipient: {
          organisation: 'hjm-translations',
          name: '',
        },
        subject: '',
        message: '',
      },
      response: null,
      messageSending: false,
      sendMessageOperationCompleted: false,
      messageSuccess: false,
    };
  },
  methods: {
    sendEmail() {
      alert('Ej implementerat');
    },
  },
};
</script>

<style lang="scss">
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 10px;
  margin: auto auto 15px auto;
  text-align: left;

  border: 1px solid var(--color-accent-dark);
  border-radius: var(--border-radius);

  &__input {
    padding: 12px 20px;
    margin-bottom: 10px;

    border: none;
    border-bottom: 3px solid var(--color-main-brand);
    background-color: transparent;
    transition: 0.25s;

    &:focus {
      border-bottom: 3px solid var(--color-accent-light);
      outline: none;
    }
  }

  &__button {
    padding: 5px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--color-shades-light);
    font-size: 1.5em;
    color: var(--color-accent-dark);
    align-content: center;
    cursor: pointer;

    &:hover {
      background-color: var(--color-accent-light);
      cursor: url('/cursor_hand.png'), auto;
    }

    &:disabled {
      background-color: var(--color-shades-dark);
    }
  }
}
</style>
