<template>
  <section class="contact">
    <h2>{{ $t('header.contact') }}</h2>
    <div class="contact__information">
      <ContactForm class="contact__form contact__flex-item" />
      <div class="coworker contact__flex-item">
        <img src="@/assets/images/coworker-hannes2.jpg" alt="Hannes" class="coworker__image">
        <h4>Hannes - Översättare</h4>
      </div>
    </div>
  </section>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'Contact',
  components: {
    ContactForm,
  },
};

</script>

<style lang="scss">
.contact {
  max-width: 600px;
  .contact__information {
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 600px) {
      flex-direction: row;
    }
  }

  &__flex-item {
    flex-basis: 0;
    flex-grow: 1;
    margin: 5px;
  }
}

.coworker {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-accent-dark);

  &__image {
    max-width: 100%;
    vertical-align: middle;
  }
}
</style>
